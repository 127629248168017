import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { t } from '@lingui/macro'
import { IClipboard } from 'contexts/ClipboardContext'
import IToolData from 'types/IToolData'
import theme, { colors } from 'shared/theme'
import ITermData from 'types/ITermData'
import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import termStyle from 'shared/components/termStyle'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import FloatingActionMenuTerm from 'components/floating-action-menu/FloatingActionMenuTerm'
import IStationData from 'types/IStationData'
import { FormStudyData } from 'components/form/StudyDataForm'

type TermProps = {
  station: IStationData
  tool: IToolData
  term: ITermData
  termIndex: number
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function Term({ station, tool, term, termIndex, clipboard, viewType }: TermProps): JSX.Element {
  const isInitialTermRender = useRef<boolean>(true)

  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const termKey = `tool${viewType === 'pin' ? '-pin' : ''}-${term.tool}-term-${term.id}`

  const { control } = useFormContext<FormStudyData>()

  useEffect(() => {
    if (isInitialTermRender.current) {
      isInitialTermRender.current = false
    }
  }, [term])

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }
    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    if (name === 'term') {
      return {
        ...generalProps,
        style: {
          ...generalStyles,
          ...theme.typography.h5,
          color: colors.black.A600,
        },
        sx: {
          '& *::placeholder': {
            ...generalStyles,
            ...theme.typography.h5,
            color: colors.black.A600,
          },
        },
      }
    }

    if (name === 'note') {
      return {
        ...generalProps,
        style: {
          ...generalStyles,
          ...theme.typography.h5,
          padding: '16px',
          border: `1px solid ${colors.gray.A600}`,
          borderRadius: '4px',
          color: colors.black.A500,
        },
        sx: {
          '& *::placeholder': {
            ...generalStyles,
            ...theme.typography.h5,
            color: colors.black.A500,
          },
        },
      }
    }
    if (name === 'hyperlink') {
      return {
        ...generalProps,
        style: {
          ...generalStyles,
          ...theme.typography.subtitle2,
        },
      }
    }

    return {
      ...generalProps,
      style: {
        ...generalStyles,
      },
    }
  }

  const isTermActive = (): boolean => !!actionMenuContext && actionMenuContext.term?.id === term.id

  const handleTermClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActionMenuContext({
      stationGridItem: null,
      station,
      toolGridItem: null,
      tool,
      stationListId: null,
      term,
    })
  }

  return (
    term && (
      <Draggable draggableId={termKey} index={termIndex} key={termKey}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Box>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={termStyle(isTermActive(), snapshot.isDragging)}
              onClick={handleTermClick}
            >
              <Box display="flex">
                <Box className="MuiTerm-dragHandle" />
                <Box flexGrow="1" padding="8px 16px" className="MuiTerm-container">
                  <Box display="flex">
                    <Box
                      display="flex"
                      width="100%"
                      alignItems="center"
                      sx={{
                        background: 'transparent',
                        padding: '0',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <FormTextFieldBlur
                        name={`terms[${term.id}].term`}
                        autoComplete="off"
                        placeholder={t`Term title`}
                        InputProps={getInputProps('term')}
                        variant="standard"
                        multiline
                        control={control as never}
                      />
                    </Box>
                    <FloatingActionMenuTerm clipboard={clipboard} station={station} term={term} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Draggable>
    )
  )
}

export default React.memo(Term)
